<template>
  <div class="mobile-page">
    <mainTree></mainTree>
  </div>
</template>
  
  <script>
import mainTree from "@/views/Tree/index.vue";

export default {
  components: {
    mainTree,
  },
};
</script>
  
  <style>
.mobile-page {
  width: 100%;
  height: 100%;
}
</style>